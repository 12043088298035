// Table of Content
// ===============================================
// # Variables
// # Mixin */

// # Variables
// =============================================== */
// No local variables available

// # Mixin
// =============================================== */

@mixin font-size( $value ) {
	font-size: rem($value);
}