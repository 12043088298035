// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */
// No local variables available

// # Elements
// =============================================== */

.steps {
	z-index: 1;
	@include position(relative, null);
	@include margin(rem(18px) null rem(70px));
	@include max-screen($breakpoint-desktop){
		@include margin(rem(40px) null null);
	}
	@include max-screen($breakpoint-small){
		@include margin(rem(20px) null rem(40px));
	}

	&:before {
		content: '';
		height: 1px;
		display: block;
		@include position(absolute, 50% 0 null 0);
		@include background-image(steps-line, png, left, repeat-x, rem(54px));
		@include max-screen($breakpoint-small){
			background-size: rem(25px);
		}
	}

	> div {
		max-width: $width-base + 180;
	}

	ul {
		z-index: 2;
		display: flex;
		@include position(relative, null);
		@include margin(0);
		li {
			flex: 1;
			text-align: center;
			@include position(relative, null);
			> div {
				font-weight: 700;
				width: rem(64px);
				height: rem(64px);
				border-radius: 50%;
				line-height: rem(48px);
				background: $color-white;
				border: 8px solid $color-primary;
				@include margin(null auto);
				@include position(relative, null);
				@include font-size($font-size + 6);
				@include max-screen($breakpoint-tablet){
					transform: scale(0.8);
				}
				@include max-screen($breakpoint-small){
					transform: scale(0.6);
				}

				&:before {
					z-index: 2;
					content: '';
					display: block;
					width: rem(80px);
					height: rem(80px);
					border-radius: 50%;
					transform: translate(-50%, -50%);
					border: 8px solid $color-primary-transparanter;
					@include position(absolute, 50% null null 50%);
				}
			}

			> span {
				opacity: 0.3;
				display: block;
				font-weight: 700;
				color: $text-color-base;
				text-transform: uppercase;
				transform: translateX(-50%);
				@include font-size($font-size + 1);
				@include padding(rem(33px) rem(5px) null);
				@include position(absolute, 100% null null 50%);
				@include max-screen($breakpoint-tablet){
					@include font-size($font-size - 3);
					@include padding(rem(20px) null null);
				}
				@include max-screen($breakpoint-small){
					@include font-size($font-size - 10);
					@include padding(rem(10px) null null);
				}
			}

			&.is-active {
				> div {
					transform: scale(1.25);
					@include max-screen($breakpoint-tablet){
						transform: scale(1);
					}
					@include max-screen($breakpoint-small){
						transform: scale(0.7);
					}
				}

				> span {
					opacity: 1;
				}
			}
		}
	}
}
