// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */
// No local variables available

// # Elements
// =============================================== */

.header {
	z-index: 99;
	@include position(relative, null);
	&__logo {
		width: 100%;
		display: block;
		max-width: rem(320px);
		@include margin(rem(30px) null null);
		@include max-screen($breakpoint-desktop){
			@include margin(rem(25px) null null);
		}
		@include max-screen($breakpoint-tablet){
			max-width: rem(300px);
			@include margin(rem(15px) null null);
		}
		@include max-screen($breakpoint-phone){
			max-width: rem(220px);
		}
		@include max-screen($breakpoint-small){
			max-width: rem(150px);
			@include margin(rem(8px) null null);
		}
	}

	&__right {
		width: rem(335px);
		@include padding(rem(57px) rem(30px));
		@include position(absolute, 0 0 null null);
		@include max-screen($breakpoint-tablet){
			width: rem(280px);
			@include padding(rem(40px) rem(30px));
		}
		@include max-screen($breakpoint-phone){
			width: rem(200px);
			@include padding(rem(30px) rem(20px));
		}
		@include max-screen($breakpoint-small){
			width: rem(130px);
			@include padding(rem(20px) rem(15px));

			img {
				height: 60px;
			}
		}

		a {
			display: block;
			max-width: rem(256px);
			@include margin(null auto);
		}
	}

	&--absolute {
		@include position(absolute, 0 null null 0);
	}
}
