// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */
// No local variables available

// # Elements
// =============================================== */

.confirm {
    @include padding(rem(90px) null rem(60px) null);
    @include max-screen($breakpoint-tablet) {
        @include padding(rem(50px) null);
    }

    ul {
        @include margin(null auto);

        li {
            display: flex;
            font-weight: 500;
            @include font-size(21px);
            @include margin(null null rem(35px));
            @include max-screen($breakpoint-tablet) {
                @include font-size(17px);
            }

            > div {
                flex: 1;
                @include padding(null rem(10px));

                &:first-child {
                    color: $text-color-gray;
                }
            }
        }
    }
}

.color-code {
    border-radius: 5px;
    display: inline-block;
}

.color-code-beam {
    @extend .color-code;
    width: 100%;
    height: 40px;
}

.color-code-inline-with-text {
    @extend .color-code;
    width: 25px;
    height: 25px;
    padding-left: 5px;
    vertical-align: text-bottom;
}
