div.check_out {
  @include position(relative, null);
  @include padding(rem(118px) null rem(150px));
  @include max-screen($breakpoint-tablet){
    @include padding(rem(50px) null rem(100));
  }
  @include max-screen($breakpoint-phone){
    @include padding(rem(20px) null);
  }

  #checkout-form {
    @extend %form;

    p, .form-group {
      display: flex;
      align-items: center;
      @include position(relative, null);
      @include margin(null null rem(18px));
      @include max-screen($breakpoint-phone){
        display: block;
      }

      .label-group {
        min-width: rem(225px);
        @include padding(null rem(10px) null null);
      }

      label {
        display: inline;
        @include margin(0);
        @include font-size($font-size - 2);
        @include max-screen($breakpoint-phone){
          width: 100%;
          text-align: center;
          @include font-size($font-size - 4);
          @include padding(null 0 rem(5px));
        }
      }

      > span {
        width: 100%;
        display: block;
        @include position(relative, null);
        > a {
          transform: translateY(-50%);
          @include position(absolute, 50% rem(25px) null null);
        }
      }
    }
  }
}
