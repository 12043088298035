//
// Magnific popup
//
// Table of Content
// ===============================================
// # Variables
// # Imports
// # Overrides */

// # Variables
// =============================================== */
// Overlay
$mfp-overlay-color: #0b0b0b !default; // Color of overlay screen
$mfp-overlay-opacity: 0.9 !default; // Opacity of overlay screen
$mfp-shadow: 0 0 8px rgba(0, 0, 0, 0.6) !default; // Shadow on image or iframe

// Spacing
$mfp-popup-padding-left: 8px !default; // Padding from left and from right side
$mfp-popup-padding-left-mobile: 6px !default; // Same as above, but is applied when width of window is less than 800px

// Zindex
$mfp-z-index-base: 1040 !default; // Base z-index of popup

// Controls
$mfp-include-arrows: true !default; // Include styles for nav arrows
$mfp-controls-opacity: 0.65 !default; // Opacity of controls
$mfp-controls-color: #ffffff !default; // Color of controls
$mfp-controls-border-color: #3f3f3f !default; // Border color of controls
$mfp-inner-close-icon-color: #333333 !default; // Color of close button when inside
$mfp-controls-text-color: #cccccc !default; // Color of preloader and "1 of X" indicator
$mfp-controls-text-color-hover: #ffffff !default; // Hover color of preloader and "1 of X" indicator
$mfp-IE7support: true !default; // Very basic IE7 support

// Iframe-type options
$mfp-include-iframe-type: true !default; // Enable Iframe-type popups
$mfp-iframe-padding-top: 40px !default; // Iframe padding top
$mfp-iframe-background: #000000 !default; // Background color of iframes
$mfp-iframe-max-width: 900px !default; // Maximum width of iframes
$mfp-iframe-ratio: 9/16 !default; // Ratio of iframe (9/16 = widescreen, 3/4 = standard, etc.)

// Image-type options
$mfp-include-image-type: true !default; // Enable Image-type popups
$mfp-image-background: #444444 !default; // Background color for images
$mfp-image-padding-top: 40px !default; // Image padding top
$mfp-image-padding-bottom: 40px !default; // Image padding bottom
$mfp-include-mobile-layout-for-image: true !default; // Removes paddings from top and bottom

// Image caption options
$mfp-caption-title-color: #f3f3f3 !default; // Caption title color
$mfp-caption-subtitle-color: #bdbdbd !default; // Caption subtitle color

// A11y
$mfp-use-visuallyhidden: false !default;

// # Imports
// =============================================== */
@import '~magnific-popup/src/css/main';

// # Overrides
// =============================================== */
.mfp-content {
	@include padding(rem(100px) null rem(20px));
	@include max-screen($breakpoint-phone){
		@include padding(rem(60px) null null);
	}
}

button.mfp-close,
.popup-modal-dismiss {
	display: block;
	overflow: hidden;
	border-radius: 50%;
	text-indent: -9999px;
	width: rem(86px)!important;
	text-align: left!important;
	height: rem(86px)!important;
	border: 2px solid $color-white;
	@include position(fixed, rem(20px) rem(20px) null null);
	@include background-image(close2, svg, center, no-repeat, 45% 45%);
	@include max-screen(1749px){
		width: rem(70px)!important;
		height: rem(70px)!important;
	}
	@include max-screen($breakpoint-desktop){
		width: rem(60px)!important;
		height: rem(60px)!important;
	}
	@include max-screen($breakpoint-phone){
		width: rem(30px)!important;
		height: rem(30px)!important;
	}
}

button {
	&.mfp-close {
		opacity: 1;
		top: rem(20px)!important;
		right: rem(20px)!important;
	}
}
