// Table of Content
// ===============================================
// # Variables
// # Mixin */

// # Variables
// =============================================== */
// No local variables available

// # Mixin
// =============================================== */

@mixin background-image( $name, $extension: 'png', $position: left center, $repeat: no-repeat, $size: false ) {
	background-image: url(#{$image-path}/#{$name}.#{$extension});
	background-position: $position;
	background-repeat: $repeat;

	@if ($size) {
		background-size: $size;
	}

	@if ($retina) {
		@include hdpi {
			background-image: url(#{$image-path}/#{$name}#{$retina-suffix}.#{$extension});
		}
	}
}