.body--light {
  background: linear-gradient(278deg, rgba(227,240,217,1) 0%, rgba(248,250,237,1) 100%);
}

.body--dark {
  background: linear-gradient(278deg, rgba(227,240,217,1) 0%, rgba(248,250,237,1) 100%);
}

.language-selection .button {
    margin-bottom: 15px;
}

.company-selection .button {
  margin-top: 10px;
  margin-bottom: 10px;
  white-space: normal; // allow breaking long company name buttons to multiple lines
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.225rem;
  line-height: 1.3;
}

label + span.required {
  display: inline;
}

#register__qrinput {
  width: 1px;
}

#register__qrinput, #register__qrinput-fastcheckout {
  opacity: 0;
  cursor: default;
}

.register__qrinput-fastcheckout-form {
    position: fixed;
    top: -100px;
    right: 0;
}

.tools .d-flex {
  min-height: 98px;
}

.alert {
  width: 60%;
  margin: 0 auto 2rem auto;
}

.language--icon {
  width: 20px;
  display: inline-block;
  vertical-align: baseline;
}

#registration-form {
  .has-error {
    & .control-label {
      color: #FF3333;
    }

    & input {
      border-color: #FF3333;
    }

    & .help-block {
      display: none !important;
    }
  }

  .has-error, .is-error {
    color: #FF3333;
  }
}

.bootstrap-datetimepicker-widget.dropdown-menu {
  width: 23rem;
  margin-bottom: 8px;
}

.example_qr {
  width: 100%;
  display: flex;
  justify-content: center;
}

.ui-sortable-handle {
  user-select: none;
}

#questions {
  > .form-group {
    display: block!important;

    .control-label {
      font-weight: bold;
    }
  }

  > .form-group > div {
    width: 100%;
    display: block;
  }

  .info {
    font-size: .8em;
  }
}

// fix for mandatory field message
.form-control.select2-hidden-accessible {
  top: 35px;
  margin-top: 2em !important;
  margin-left: 65% !important;

}

.header__right{
  width: auto;
}

.header__right img{
  max-height: 6.5rem;
}

#edit-registration-form .help-block{
  margin-left:10px;
  font-size:15px;
}