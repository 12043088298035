// Table of Content
// ===============================================
// # Variables
// # Mixin */

// # Variables
// =============================================== */
// No local variables available

// # Mixin
// =============================================== */

@mixin clearfix {
	&::after {
		clear: both;
		content: "";
		display: block;
	}
}
