// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */
// No local variables available

// # Elements
// =============================================== */

.printing {
	@include padding(rem(100px) null rem(150px));
	@include max-screen($breakpoint-tablet){
		@include padding(rem(100px) null);
	}
	@include max-screen($breakpoint-phone){
		text-align: center;
		@include padding(rem(50px) null);
	}

	p {
		font-weight: 500;
		@include font-size($font-size - 2);
	}

	h2 {
		font-weight: 700;
		font-family: $font-family-primary;
		@include font-size(60px);
		@include margin(rem(30px) null null);
		@include max-screen($breakpoint-desktop + 200){
			@include font-size(45px);
		}
		@include max-screen($breakpoint-phone){
			@include font-size(35px);
		}
	}

	h4 {
		font-weight: 400;
		font-family: $font-family-primary;
		@include font-size(40px);
		@include margin(null null rem(20px));
		@include max-screen($breakpoint-desktop + 200){
			@include font-size(35px);
		}
		@include max-screen($breakpoint-phone){
			@include font-size(30px);
		}
	}

	img {
		max-width: rem(243px);
		@include max-screen($breakpoint-phone){
			@include margin(null auto rem(20px))
		}
		@include max-screen($breakpoint-small){
			max-width: rem(200px);
		}
	}

	.fa-spinner,
	.fa-exclamation-triangle {
		font-size: 3.5rem;
	}

	.after_printing_instructions_popup {
		background-color: rgba(0, 0, 0, 0.6);
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 100;
		height: 100vh;
		display: flex;
		justify-content: center;
		align-items: center;
		.popup_content {
			max-width: 70%;
			max-height: 70%;
			background-color: $color-light;
			border-radius: 2rem;
			border: 8px solid $color-primary;
			padding: 3rem;

			h2 {
				padding-bottom: 3rem;
			}
		}
	}

	.badge-printing-wait-noti{
		display:inline-block;height:35px;
	}
	.badge-printing-wait-noti span{
		width:300px;
		text-align:center;
		position:absolute;
		margin-left:20px;
		font-size:1.0rem;
		padding:10px;
		background-color:#fff;
		border-color:#7e7e7e;
		border-radius: 18px;
		border-width: 1px;
		border-style: solid;
		box-shadow:1px 1px #c1c1c1;
	}

	@media only screen and (max-width: 767px) {
		.badge-printing-wait-noti span {
			font-size: 0.9rem;
			position: relative;
			display:inline-block;
		}
	}
}
