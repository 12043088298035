// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */
// No local variables available

// # Elements
// =============================================== */

.help {
	display: flex;
	overflow: auto;
	height: rem(845px);
	border-radius: 10px;
	align-items: center;
	background: $color-primary;
	@include padding(rem(50px) rem(120px));
	@include max-screen($breakpoint-desktop){
		height: auto;
		@include padding(rem(100px) rem(120px));
	}
	@include max-screen($breakpoint-tablet){
		@include padding(rem(100px) rem(50px));
	}
	@include max-screen($breakpoint-phone){
		@include padding(rem(50px));
	}
	@include max-screen($breakpoint-small){
		@include padding(rem(50px) rem(20px));
	}

	p {
		line-height: 1.6;
		font-family: $font-family-primary;
		@include font-size(25px);
		@include margin(null null rem(80px));
		@include max-screen($breakpoint-phone){
			@include font-size(20px);
			@include margin(null null rem(30px));
		}
	}

	h2 {
		font-weight: 700;
		@include font-size($font-size-h2);
		@include margin(null null rem(40px));
		@include max-screen($breakpoint-phone){
			@include font-size($font-size-h2 - 5);
		}
	}

	&__align {
		max-width: 1100px;
	}

	&__logo {
		display: block;
		max-width: rem(378px);
	}

	&__about {
		max-width: 900px;
		text-align: center;
		@include margin(null auto);
		h2 {
			@include margin(null null rem(50px));
			@include max-screen($breakpoint-phone){
				@include margin(null null rem(20px));
			}
		}

		.help__logo {
			@include margin(null auto);
		}
	}
}