// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */
// No local variables available

// # Elements
// =============================================== */

.checkbox {
	cursor: pointer;
	width: rem(36px);
	height: rem(36px);
	border-radius: 10px;
	display: inline-block;
	background: $color-white;
	border: 1px solid $color-primary;
	@include position(relative, null);
	> span {
		opacity: 0;
		display: block;
		@include position(absolute, 0 0 0 0);
		@include background-image(check-icon, svg, center, no-repeat, rem(32px) rem(25px));
	}

	input[type="checkbox"] {
		z-index: 1;
		opacity: 0;
		cursor: pointer;
		width: rem(52px)!important;
		height: rem(52px)!important;
		@include margin(0);
		@include position(absolute, 0 null null 0);
	}

	input[type="checkbox"]:checked ~ span {
		opacity: 1;
	}
}
