// Table of Content
// ===============================================
// # Welcome
// # Settings
// # Frameworks
// # Tools
// # Generic
// # Base
// # Components
// # Visual Composer
// # Plugins
// # Fonts
// # Vendors
//
// # Welcome
//   This file is used to centralize the imports
// =============================================== */
//
// Most of the following guidelines used to write css:
// http://cssguidelin.es
//
// Bower is used for keeping the used libraries up to date
// more information is available at http://bower.io
//
// Import this file using the following HTML or equivalent:
// <link href="dist/css/main.css" media="all" rel="stylesheet" type="text/css" />
//
// # Settings
//   Globally-available variables and configs
// =============================================== */
@import 'variables';
//
// # Frameworks
//   Bourbon, neat, bootstrap
// =============================================== */
@import '~bourbon/core/bourbon';
@import '~bourbon-neat/core/neat';
@import '~bootstrap/scss/bootstrap';
//
// # Helpers
//   Useful mixins,functions and helpers
// =============================================== */
@import 'functions/_rem.scss';
@import 'functions/_strip-unit.scss';
@import 'mixins/_arrow.scss';
@import 'mixins/_background-image.scss';
@import 'mixins/_clearfix.scss';
@import 'mixins/_font-size.scss';
@import 'mixins/_media.scss';
@import 'mixins/_no-gutter-last-child.scss';
@import 'helpers/_addresses.scss';
@import 'helpers/_alignment.scss';
@import 'helpers/_blockquotes.scss';
@import 'helpers/_codes.scss';
@import 'helpers/_lists.scss';
@import 'helpers/_tables.scss';
@import 'helpers/_typography.scss';
//
// # Generic
//   Normalize style across browsers
// =============================================== */
@import '~normalize-scss/sass/_normalize';
//
// # Base
// Resets, grids, wrappers, body, typography and forms
// =============================================== */
@import 'base/reset';
@import 'base/base';
@import 'base/buttons';
@import 'base/content';
@import 'base/form';
@import 'base/media';
@import 'base/wrapper';
//
//   Header, footer, sidebars etc
// =============================================== */
@import 'components/_check_out.scss';
@import 'components/_checkbox.scss';
@import 'components/_confirm.scss';
@import 'components/_enter.scss';
@import 'components/_footer.scss';
@import 'components/_header.scss';
@import 'components/_help.scss';
@import 'components/_instructions.scss';
@import 'components/_preregistration.scss';
@import 'components/_printing.scss';
@import 'components/_qr_scanner_overlay.scss';
@import 'components/_question.scss';
@import 'components/_register.scss';
@import 'components/_registrations_temporarily_disallowed.scss';
@import 'components/_steps.scss';
@import 'components/_subscribe.scss';
@import 'components/_tools.scss';
@import 'components/_welcome.scss';
//
// # Visual Composer
//   Load plugin components
// =============================================== */
//@import 'visual-composer/*';
//
// # Plugins
//   Apply theme styling to plugins
// =============================================== */
//@import 'plugins/*';
@import '~tempusdominus-bootstrap-4/build/css/tempusdominus-bootstrap-4.min.css';
//
// # Fonts
//   Libraries used in the theme
// =============================================== */
@import '~font-awesome/css/font-awesome.min.css';
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,700|Ubuntu:400,700');
//
// # Vendors
//   Vendor includes
// =============================================== */
@import 'vendor/intl-tel-input/intl-tel-input.scss';
@import 'vendor/magnific-popup/magnific-popup.scss';
@import 'vendor/mmenu/mmenu.scss';
@import 'vendor/select2/select2.scss';
//
// # Custom CSS
//   Our own custom styling rules, possibly overriding the base css we got
@import 'custom';
