// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */
// No local variables available

// # Elements
// =============================================== */

.ratio,
audio,
embed,
iframe,
object,
video {
	@include margin(null null rem(20px));
}

.ratio {
	display: block;
	overflow: hidden;
	height: 0;
	@include padding(0);
	@include position(relative, null);

	embed,
	iframe,
	object,
	video {
		height: 100%;
		width: 100%;
		border: 0;
		@include position(absolute, 0 null null 0);
	}
}

.ratio--16x9 {
	@include padding(null null 56.25%);
}

.ratio--4x3 {
	@include padding(null null 75%);
}
