// Table of Content
// ===============================================
// # Variables
// # Mixins */

// # Variables
// =============================================== */
// No local variables available

// # Mixins
// =============================================== */

@mixin mq($args...) {
	$media-type: 'only screen';
	$media-type-key: 'media-type';
	$args: keywords($args);
	$expr: '';

	@if map-has-key($args, $media-type-key) {
		$media-type: map-get($args, $media-type-key);
		$args: map-remove($args, $media-type-key);
	}

	@each $key, $value in $args {
		@if $value {
			$expr: "#{$expr} and (#{$key}: #{$value})";
		}
	}

	@media #{$media-type} #{$expr} {
		@content;
	}
}

@mixin screen($min, $max, $orientation: false) {
	@include mq($min-width: $min, $max-width: $max, $orientation: $orientation) {
		@content;
	}
}

@mixin max-screen($max) {
	@include mq($max-width: $max) {
		@content;
	}
}

@mixin min-screen($min) {
	@include mq($min-width: $min) {
		@content;
	}
}

@mixin screen-height($min, $max, $orientation: false) {
	@include mq($min-height: $min, $max-height: $max, $orientation: $orientation) {
		@content;
	}
}

@mixin max-screen-height($max) {
	@include mq($max-height: $max) {
		@content;
	}
}

@mixin min-screen-height($min) {
	@include mq($min-height: $min) {
		@content;
	}
}

@mixin hdpi($ratio: 1.3) {
	@media only screen and (-webkit-min-device-pixel-ratio: $ratio),
	only screen and (min-resolution: #{round($ratio*96)}dpi) {
		@content;
	}
}

@mixin landscape() {
	@include mq($orientation: landscape) {
		@content;
	}
}

@mixin portrait() {
	@include mq($orientation: portrait) {
		@content;
	}
}