// Table of Content
// ===============================================
// # Variables
// # Function */

// # Variables
// =============================================== */
// No local variables available

// # Function
// =============================================== */

@function rem($pxval) {
	@if not unitless($pxval) {
		$pxval: strip-unit($pxval);
	}

	$base: $em-base;
	@if not unitless($base) {
		$base: strip-unit($base);
	}

	@return ($pxval / $base) * 1rem;
}