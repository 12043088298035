.select2-container {
    &.select2-container--gatehouse {
        width: 100% !important;

        .select2-selection__placeholder{
            color:#ccc;
        }

        .select2-selection {
            height: rem($input-height-base);
            font-weight: 500;
            @include font-size(18px);
            @include position(relative, null);
            @include max-screen($breakpoint-phone){
                height: rem($input-height-base - 20);
            }

            float: none;
            display: block;
            text-indent: 0;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            color: $text-color-base;
            background: $color-white;
            border: 1px solid $color-primary;
            line-height: rem($input-height-base);
            border-radius: $input-border-radius-base;
            @include padding(null rem(25px) null rem(30px));
            @include max-screen($breakpoint-phone){
                height: rem($input-height-base - 20);
                line-height: rem($input-height-base - 20);
            }
        }

        .select2-selection--single {
            .select2-selection__rendered {
                padding: 0;
            }
            .select2-selection__arrow {
                float: none;
                height: 100%;
                width: 3.125rem;
                background-color: transparent;
                position: absolute;
                top: 0;
                right: 0;
                background-image: url('../../../img/select-arrow.svg');
                background-position: left;
                background-repeat: no-repeat;
                background-size: 1.125rem;
            }
        }

        .select2-dropdown {
            &.select2-dropdown--below {
                background: none;
                border: none;
                @include padding(0 rem(30px));
            }
            .select2-search--dropdown, .select2-results {
                background-color: $color-primary;
            }
            .select2-search--dropdown {
                @include padding(rem(10px) rem(10px) 0 rem(10px));
            }
            .select2-search__field {
                border: none;
                border-radius: 6px;
                line-height: 32px;
                padding: 4px 10px;
            }
            .select2-results {
                @include padding(rem(10px) rem(10px) rem(10px) rem(10px));
            }
            .select2-results__options {
                @include padding(null rem(20px) null null);
                max-height: 230px;
                overflow-y: auto;
            }
            .select2-results__option {
                @include padding(0);
                border-bottom: 1px solid rgba($color-white, 0.5);
                text-indent: 0;
                font-weight: 500;
                @include font-size(18px);
                color: $text-color-white;
                background: $color-primary;
                line-height: 35px;
                @include position(relative, null);
                @include padding(rem(10px) 0 rem(5px));
                min-height: 40px; // for empty elements
                &[aria-selected="true"] {
                    @include padding(null rem(35px) null null);
                    &:after {
                        content: '';
                        display: block;
                        width: rem(26px);
                        height: rem(18px);
                        transform: translateY(-50%);
                        @include position(absolute, 50% rem(5px) null null);
                        @include background-image(selected, svg, center, no-repeat, 100% 100%);
                    }
                }
            }
        }
    }
}
