// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */
// No local variables available

// # Elements
// =============================================== */

.subscribe {
	border-radius: 10px;
	background: $color-white;
	@include padding(rem(50px) rem(50px) rem(10px));
	@include max-screen($breakpoint-tablet){
		@include padding(rem(30px) rem(30px) null);
	}
	@include max-screen($breakpoint-phone){
		@include padding(rem(30px) rem(15px) null);
	}

	p {
		line-height: 1.6;
		color: $text-color-black-light;
		@include font-size(21px);
	}

	h2 {
		font-weight: 700;
		@include font-size($font-size-h2);
		@include margin(null null rem(30px));
		@include max-screen($breakpoint-tablet){
			@include font-size($font-size-h2 - 5);
		}

		br {
			@include max-screen($breakpoint-phone){
				display: none;
			}
		}
	}

	form {
		display: flex;
		max-width: 845px;
		align-items: center;
		@extend %form;
		@include margin(rem(100px) null null);
		@include max-screen($breakpoint-tablet){
			@include margin(rem(30px) null null);
		}
		@include max-screen($breakpoint-phone){
			display: block;
			text-align: center;
		}

		label {
			white-space: nowrap;
			@include margin(0);
			@include max-screen($breakpoint-phone){
				@include margin(null null rem(10px));
			}
		}

		input[type="email"],
		input[type="text"]{
			@include margin(null rem(30px));
			@include max-screen($breakpoint-phone){
				@include margin(null 0 rem(10px));
			}
		}

		.btn,
		.button {
			min-width: rem(250px);
			@include max-screen($breakpoint-tablet){
				min-width: rem(200px);
			}
		}
	}

	&__logo {
		display: block;
		max-width: rem(375px);
	}

	&__right {
		@include padding(rem(80px) null null);
		@include max-screen($breakpoint-tablet){
			@include padding(rem(30px) null null);
		}
	}

	&__client {
		@include padding(rem(120px) null null);
		@include max-screen($breakpoint-tablet){
			@include padding(rem(50px) null null);
		}

		h4 {
			font-weight: 700;
			@include font-size($font-size-h4 + 1);
			@include padding(null null rem(20px));
			@include background-image(steps-line, png, left bottom, repeat-x, rem(54px));
		}

		ul {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			@include margin(0);
			li {
				flex: auto;
				text-align: center;
				@include padding(rem(20px) rem(10px));
				img {
					width: 100%;
					max-width: rem(140px);
					@include margin(null auto);
					@include max-screen($breakpoint-desktop){
						max-width: rem(120px);
					}
				}
			}
		}
	}
}