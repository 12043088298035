.register {
  #pre-registration-form, #invite-form, #edit-registration-form {
    @extend #registration-form;

    .form-group label.control-label {
      font-size: 1rem;
    }

    .select2-container.select2-container--gatehouse .select2-selection,
    .form-group input.form-control {
      height: 3rem;
      line-height: 3rem;
      font-weight: normal;
    }

    .select2-container--disabled .select2-selection {
      color: #999;
      background-color: #efefef;
      cursor: pointer;
    }

    button {
      line-height: 0.4;
      font-size: 1rem;

      a {
        color: white;
        cursor: pointer;
      }
    }

    #preregistration_visitor .form-group:last-of-type {
      float: right;
    }

    .bootstrap-datetimepicker-widget .btn[data-action='incrementHours'],
    .bootstrap-datetimepicker-widget .btn[data-action='decrementHours'],
    .bootstrap-datetimepicker-widget .btn[data-action='incrementMinutes'],
    .bootstrap-datetimepicker-widget .btn[data-action='decrementMinutes'] {
      display: none !important;
    }
  }
}

.preregistration-form-wrapper, .edit-registration-form-wrapper{
  .action-buttons {
    display: flex;
    justify-content: space-between;
    padding: 0 1rem 0 1rem;
  }
}

.preregistration-overview {
  h1 {
    margin-top: 1rem;
  }

  table {
    margin-top: 2rem;
    font-size: .9rem;

    i.fa {
      color: #000;
      font-size: 1.25rem;
    }

    .authorization-button {
      i.fa {
        color: $text-color-white !important;
      }

      background-color: rgba($color-primary, 0.30);
      font-size: 1.1rem;
      padding: .5rem;
      color: $text-color-white !important;
    }

    .active {
      background-color: $color-primary !important;
      box-shadow: none !important;
    }

    .checkout-visitor {
      cursor: pointer;

      svg {
        width: 24px;
      }
    }

    .resend-badge {
      cursor: pointer;
    }

    .needs_approval-preregistration {
      padding-right: .8rem !important;
    }
  }

  .search-bar {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    margin-top: 1rem;

    form {
      @extend %form;
      width: 100%;

      .select2-container.select2-container--gatehouse .select2-selection,
      input.search-input,
      .search-submit {
        height: 3rem;
        line-height: 3rem;
        font-weight: normal;
      }

      .search-submit {
        line-height: 0.4;
        font-size: 1rem;
      }

      label {
        margin: 0;
        text-transform: none;
        font-weight: normal;
      }
    }
  }

  .logout-button {
    position: absolute;
    right: 0;
    top: 0;
    margin: 1rem;
    z-index: 200;
  }

  .button-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 2rem;

    .page-button {
      color: $text-color-black;
      padding: 1em;
      margin-left: .5rem;
      margin-right: .5rem;
      margin-bottom: 0;
      border-radius: 1rem;
      line-height: 1;
      vertical-align: middle;
      font-weight: 700;
      font-size: 1rem;
      display: inline-block;
    }

    .page-button:hover {
      background-color: $color-primary;
    }

    .current {
      background-color: $color-primary;
    }

    .disabled {
      color: $text-color-gray;
    }

    .disabled:hover {
      background-color: transparent;
      cursor: default;
    }
  }
}
