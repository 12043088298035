// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */
// No local variables available

// # Elements
// =============================================== */

* {
	box-sizing: border-box;
	@include margin(0);
	@include padding(0);

	&:before,
	&:after {
		box-sizing: border-box;
		@include margin(0);
		@include padding(0);
	}
}

html,
body {
	font-size: $em-base;
	background-size: cover;
	color: $text-color-base;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-position: center top;
	@include max-screen($breakpoint-tablet) {
		font-size: ceil($em-base * 0.95);
	}
	@include max-screen($breakpoint-phone) {
		font-size: ceil($em-base * 0.85);
	}
}

a {
	outline: none;
	color: $link-color-base;
	text-decoration: $link-decoration-base;
	transition: $base-duration $base-timing;
	&:hover {
		color: $link-hover-color-base;
		text-decoration: $link-hover-color-base;
	}
}

img {
	height: auto;
	max-width: 100%;
}

.clear {
	@include clearfix;
}

.container {
	width: 100%;
	max-width: $width-base + 30;
}

.popup {
	z-index: 99;
	width: 100%;
	height: 100%;
	background: rgba($color-black, 0.8);
	@include position(fixed, 0 null null 0);
}