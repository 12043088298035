// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */
// No local variables available

// # Elements
// =============================================== */

.question {
	display: block;
	font-weight: 700;
	width: rem(30px);
	height: rem(30px);
	text-align: center;
	border-radius: 50%;
	color: $text-color-base;
	border: 1px solid lighten($color-primary, 30%);
	@include font-size(22px);
}
