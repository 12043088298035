// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */
$button-padding: rem(20px) rem(25px) !default;

$button-height: 75px !default;

$button-font-family: $font-family-base !default;
$button-font-size: 26px !default;
$button-font-weight: 700 !default;

$button-color: #fff !default;
$button-color-hover: #fff !default;
$button-color-disabled: #999 !default;

$button-border-size: 0 !default;
$button-border-style: solid !default;
$button-border-color: #2e6da4 !default;
$button-border-color-hover: #286090 !default;
$button-border-color-disabled: #666 !default;
$button-border-radius: rem(40px) !default;

$button-bg: #000 !default;
$button-bg-hover: lighten($button-bg, 20%) !default;
$button-bg-disabled: #efefef !default;

// # Elements
// =============================================== */

.btn,
.button {
	line-height: 1;
	cursor: pointer;
	user-select: none;
	text-align: center;
	white-space: nowrap;
	display: inline-block;
	vertical-align: middle;
	text-transform: uppercase;
	touch-action: manipulation;
	//height: rem($button-height);
	font-family: $button-font-family;
	font-weight: $button-font-weight;
	border-radius: $button-border-radius;
	transition: $base-duration $base-timing;
	border: $button-border-size $button-border-style $button-border-color;
	@include margin(null null 0);
	@include padding($button-padding);
	@include font-size($button-font-size);
	@include max-screen($breakpoint-tablet) {
		height: rem($button-height - 10);
		@include font-size($button-font-size - 6);
		@include padding(rem(23px) rem(30px));
	}
	@include max-screen($breakpoint-phone) {
		height: rem($button-height - 20);
		@include font-size($button-font-size - 8);
		@include padding(rem(20px) rem(25px));
	}

	&:hover {
		text-decoration: none;
	}

	&.button,
	&.btn-default {
		color: $button-color;
		background: $button-bg;
		&:hover,
		&:focus {
			color: $button-color-hover;
			background-color: $button-bg-hover;
		}
	}

	&.btn-primary,
	&.button--primary {
		background: $color-primary;
		&:hover {
			background: darken($color-primary, 10%);
		}
	}

	&:disabled,
	&.is-disabled {
		color: $button-color-disabled;
		background-color: $button-bg-disabled;
	}

	&--example {
		border-color: #4cae4c;
		background: #5cb85c;

		&:hover,
		&[type='submit']:hover,
		&[type='reset']:hover,
		&[type='button']:hover,
		&:focus,
		&.is-focused {
			border-color: #4cae4c;
			background-color: #4cae4c;
		}
	}
}
