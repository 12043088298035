$flagsImagePath: '~intl-tel-input/build/img/';
$flagPadding: 18px !default;
$arrowPadding: 6px !default;
@import '~intl-tel-input/src/css/intlTelInput';

.iti__selected-flag {
    border-top-left-radius: 2.5rem;
    border-bottom-left-radius: 2.5rem;
}

.register #registration-form input.phone-number-selector {
    padding-left: 4rem;
}
