// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */
// No local variables available

// # Elements
// =============================================== */

.welcome {
	min-height: 80vh;
	@include position(relative, null);
	@include padding(rem(170px) null rem(50px) 0);
	@include max-screen($breakpoint-tablet){
		@include padding(rem(120px) null null null);
	}
	@include max-screen($breakpoint-phone){
		@include padding(rem(90px) null rem(20px) null);
	}
	@include max-screen($breakpoint-small){
		@include padding(rem(60px) null null null);
	}

	&__overflow {
		width: 100%;
		overflow: auto;
		max-height: 100%;
	}

	h1 {
		font-weight: 700;
		color: $text-color-white;
		font-family: $font-family-primary;
		@include font-size(64px);
		line-height: 1;
		@include margin(null null rem(70px));
		@include max-screen($breakpoint-tablet){
			@include font-size(68px);
			@include margin(null null rem(50px));
		}
		@include max-screen($breakpoint-phone){
			@include font-size(55px);
			@include margin(null null rem(30px));
		}
		@include max-screen($breakpoint-small){
			@include font-size(45px);
		}
	}

	.btn,
	.button {
		@include max-screen($breakpoint-tablet){
			min-width: rem(220px);
			@include margin(null null rem(10px));
		}
		@include max-screen($breakpoint-tablet){
			min-width: rem(180px);
		}
	}

	form {
		max-width: 630px;
		@extend %form;
		@include margin(null auto);

		.label-group {
			min-width: 200px;

			label {
				display: inline-block;
				min-width: initial;
			}
		}

		label {
			min-width: 200px;
			text-align: left;
			font-weight: 400;
			text-transform: none;
			color: $text-color-base;
			font-family: $font-family-primary;
			@include font-size(24px);
			@include margin(0 rem(10px) 0 0);
			@include max-screen($breakpoint-small){
				text-align: center;
				@include margin(0 0 rem(5px));
			}
		}

		p, .form-group {
			display: flex;
			align-items: center;
			justify-content: space-between;
			@include margin(null null rem(30px));
			@include max-screen($breakpoint-small){
				display: block;
				@include margin(null null rem(15px));
			}

			a {
				font-weight: 700;
				color: $text-color-base;
				text-transform: uppercase;
				@include font-size(19px);
				@include max-screen($breakpoint-small){
					display: inline-block;
					@include margin(null rem(10px) rem(10px));
				}
			}
		}

		.btn,
		.button {
			min-width: 0;
		}
	}

	a.link {
		font-weight: 700;
		color: $text-color-base;
		text-transform: uppercase;
		font-size: 19px;
	}
}

// fixes
.sonata_user_admin_resetting_request .welcome form .label-group label, .sonata_user_admin_resetting_reset .welcome form .label-group label {
	display: inline;
}

