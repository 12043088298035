// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */
// No local variables available

// # Elements
// =============================================== */

.instructions {
	@include padding(rem(30px) null rem(30px));
	@include max-screen($breakpoint-phone){
		@include padding(rem(30px) null);

		.checkbox-label-group {
			left: 2rem;
			position:relative;
		}
	}

	/*.checkbox {
		transform: translateY(-50%);
		@include position(absolute, 50% null null 0);
	}*/

	a {
		display: inline-block;
		color: $color-black;
		vertical-align: middle;
	}

	label {
		cursor: pointer;
		display: inline;
		line-height: 1.2;
		font-weight: 700;
		font-family: $font-family-primary;
		@include font-size(24px);
		@include position(relative, null);
		/*@include margin(null null rem(35px));*/
		@include padding(rem(13px) null);
		@include max-screen($breakpoint-phone){
			@include font-size(20px);
			@include margin(null null rem(10px));
		}

		&.is-active {
			color: rgba($text-color-base, 0.2);
		}
	}

	/*&__video {
		max-width: 780px;
		@include max-screen($breakpoint-tablet){
			@include margin(null auto);
		}

		a {
			display: block;
			overflow: hidden;
			border-radius: 20px;
			border: 1px solid $color-primary;
			@include position(relative, null);
			&:after {
				z-index: 1;
				content: '';
				width: 100%;
				height: 100%;
				display: block;
				@include position(absolute, 0 null null 0);
				@include background-image(play-icon, svg, center, no-repeat, rem(160px) rem(160px));
				@include max-screen($breakpoint-phone){
					background-size: rem(100px) rem(100px);
				}
			}
		}

		img {
			width: 100%;
		}
	}*/

	.question {
		display: inline-block !important;
		background-color: #FFFFFF;
		@extend .question;
	}

	.checkbox-label-group {
		label {
			/*padding-left: 2rem;*/
			font-size: 1em;
			vertical-align: middle;
		}

		.checkbox {
			float: right;
			top: .5rem;
		}
	}

	.instruction {
		line-height: 2.5em;
		margin-right: 15px;
	}
}

button.btn-instruction {
	font-size: 1.125em;

	&:hover {
		background-color: $color-light!important;
		color: $color-black!important;
	}

	&:disabled {
		cursor: default;
	}
}

button.left-instruction{
	display: none!important;
}

.btn-instruction {
	position: relative;
	display: block;
	margin: auto;
	bottom: 75px;

	@include portrait {
		top: -5.5em;
		margin-bottom: -3.5em;
		left: auto;
	}
}

.mfp-iframe-holder .mfp-content {
	@include portrait {
		height: 85%;
	}
	max-height: 100% !important;
	max-width: 1080px !important;
}

#instructions_form .title {
	font-size: 1.25em;
	font-weight: bold;
}

.btn-small {
	font-size: .75em;
	height: auto;
}

.mfp-iframe-holder div.mfp-counter {
	width: 100%!important;
	height: 1em!important;
	position: absolute!important;
	display: block;
	text-align: right;
	top: -1.5rem !important;
	font-size: 1.25em;
}

#instructions-completed {
	.alert {
		margin-bottom: 0;
		width: 100%;
	}
}
