// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */
// No local variables available

// # Elements
// =============================================== */

.tools {
    @include max-screen($breakpoint-small) {
        text-align: center;
    }

    &__restart,
    &__cancel,
    &__about {
        font-weight: 700;
        display: inline-block;
        color: $text-color-base;
        text-transform: uppercase;
        line-height: rem(40px);
        @include font-size(20px);
        @include position(relative, null);
        @include padding(null null null rem(45px));

        &:before {
            content: '';
            display: block;
            width: rem(36px);
            height: rem(36px);
            border-radius: 50%;
            background-color: $color-white;
            border: 2px solid $color-primary;
            @include position(absolute, 0 null null 0);
        }
    }

    &__cancel {
        &:before {
            @include background-image(close, svg, center, no-repeat, rem(18px) rem(18px));
        }
    }

    &__about {
        &:before {
            content: '?';
            text-align: center;
            line-height: rem(34px);
            @include font-size(28px);
        }
    }

    &__restart {
        &:before {
            content: '>';
            text-align: center;
            line-height: rem(34px);
            @include font-size(28px);
        }
    }

    &__checkout {
        max-width: rem(180px);
        width: 100%;
        display: inline-block;
        position: absolute;
        bottom: 6rem;
        right: 2.5rem;
    }

    .btn,
    .button {
        min-width: rem(200px);
    }

    p {
        @include margin(0);
        @include max-screen($breakpoint-small) {
            width: 100%;
        }
    }
}
