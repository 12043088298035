// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */
// No local variables available

// # Elements
// =============================================== */

.enter {
	min-height: 80vh;
	@include position(relative, null);
	@include padding(rem(170px) null rem(50px) 0);
	@include max-screen($breakpoint-desktop){
		@include padding(null null rem(50px) null);
	}
	@include max-screen($breakpoint-tablet){
		@include padding(rem(120px) null null null);
	}
	@include max-screen($breakpoint-phone){
		@include padding(rem(90px) null rem(20px) null);
	}
	@include max-screen($breakpoint-small){
		@include padding(rem(60px) null null null);
	}

	&__overflow {
		width: 100%;
		overflow: auto;
		max-height: 100%;
	}

	a:not(.button){
		display: block;
		max-width: rem(180px);
		@include margin(null auto rem(58px));
		@include max-screen($breakpoint-desktop){
			max-width: rem(200px);
			@include margin(null null rem(40px));
		}
		@include max-screen($breakpoint-small){
			max-width: rem(150px);
			@include margin(rem(35px) null rem(20px));
		}
	}

	.btn,
	.button {
		min-width: rem(250px);
	}
}
