// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */
// No local variables available

// # Elements
// =============================================== */

.wrapper {
	width: 100%;
	overflow: hidden;
	color: $text-color-base;
	font-family: $font-family-base;
	line-height: $line-height-base;
	@include font-size($font-size);
}
