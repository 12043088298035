#qrscanner_overlay {
    background-color: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    color: white;
    canvas {
        max-width: 80%;
    }
}

#registration_nextQrCode {
    font-size: 1.5em;
    padding: .5em 1em !important;
    background-color: $color-primary!important;
}
