.registrations_temporarily_disallowed_overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    span {
        font-size: 40px;
        color: red;
        font-weight: bold;
        background: rgba(255, 255, 162,0.8);
        padding: 48px 48px;
        border-radius: 24px;
        box-shadow: 0 0 20px #0000003b;
    }
}

.blurred_overlay {
    /* Any browser which supports CSS3 */
    filter: blur(8px);
    /* Firefox version 34 and earlier */
    filter: url("../../img/blur_overlay.svg#gaussian_blur");
    /* Webkit in Chrome 52, Safari 9, Opera 39, and earlier */
    -webkit-filter: blur(8px);
}
