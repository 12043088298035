// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */
// No local variables available

// # Elements
// =============================================== */

.register {
	@include position(relative, null);
	@include padding(rem(60px) null rem(60px));
	@include max-screen($breakpoint-tablet){
		@include padding(rem(50px) null rem(100));
	}
	@include max-screen($breakpoint-phone){
		@include padding(rem(20px) null);
	}

	> div {
		max-width: $width-base - 60;
	}

	#registration-form {
		@extend %form;
		p, .form-group {
			display: flex;
			align-items: center;
			@include position(relative, null);
			@include margin(null null rem(18px));
			@include max-screen($breakpoint-phone){
				display: block;
			}

			.label-group {
				min-width: rem(225px);
				@include padding(null rem(10px) null null);
			}

			label {
				display: inline;
				@include margin(0);
				@include font-size($font-size - 2);
				@include max-screen($breakpoint-phone){
					width: 100%;
					text-align: center;
					@include font-size($font-size - 4);
					@include padding(null 0 rem(5px));
				}
			}

			> span {
				width: 100%;
				display: block;
				@include position(relative, null);
				> a {
					transform: translateY(-50%);
					@include position(absolute, 50% rem(25px) null null);
				}
			}
		}
	}

	.question {
		@extend .question;
	}

	&__code {
		text-align: center;
		border-radius: 15px;
		max-width: 14.5rem;
		background: $color-white;
		border: 1px solid $color-primary;
		@include padding(rem(25px) rem(28px));
		@include position(absolute, rem(30px) rem(30px) null null);
		@include max-screen(1549px){
			max-width: rem(220px);
			@include padding(rem(25px) rem(20px));
			@include position(absolute, rem(30px) rem(20px) null null);
		}
		@include max-screen(1449px){
			max-width: rem(180px);
			@include padding(rem(20px) rem(20px));
		}
		@include max-screen($breakpoint-tablet){
			max-width: rem(250px);
			@include margin(null auto);
			@include position(static, null);
		}

		svg {
			display: block;
			@include margin(null null rem(18px));
		}

		> a:not(.register__question) {
			font-weight: 700;
			letter-spacing: -0.5px;
			color: $text-color-base;
			text-transform: uppercase;
			border-bottom: 1px solid lighten($color-black, 50%);
			@include margin(0);
			@include font-size($font-size - 5);
			@include padding(null null rem(2px));
			@include max-screen(1449px){
				@include font-size($font-size - 9);
			}
		}

		> a {
			@include margin(rem(25px) auto rem(8px));
		}
	}
}
