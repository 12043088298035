// Table of Content
// ===============================================
// # General
// # Grid
// # Typography */

// # General
// =============================================== */

// Proper REM calculation
$em-base: 16px; // Do not change this unless you know what you are doing

// Image Path
$image-path: '../../img';

// Retina suffix
$retina: false;
$retina-suffix: '@2x';

// Width
$width-base: 1560px; // Mainly used for centering

// Gutters
$gutter-base: 20px;
$gutter-top: 0;
$gutter-right: $gutter-base;
$gutter-bottom: $gutter-base;
$gutter-left: $gutter-base;
$gutter-side: 20px; // Used when base width is reached
$gutter-side-tablet: 20px; // Used when base width is reached
$gutter-side-phone: 20px; // Used when base width is reached

// Colors
$color-error: #d9534f;
$color-success: #5cb85c;
$color-info: #5bc0de;
$color-primary: rgb(115,179,62);
$color-primary-transparanter: transparentize($color-primary, .9);
$color-white: #fff;
$color-black: #000;
$color-light: #fbfbee;

// # Grid
// =============================================== */

// General

// Breakpoints
$breakpoint-small: 575px;
$breakpoint-phone: 767px;
$breakpoint-tablet: 991px;
$breakpoint-desktop: 1199px;

// # Animations
// =============================================== */

$base-duration: 0.3s;
$base-timing: ease-in-out;

// # Typography
// =============================================== */

$text-color-base: #000;
$text-color-gray: #4d4d4d;
$text-color-primary: $color-primary;
$text-color-white: #fff;
$text-color-black: #000;
$text-color-black-light: lighten($text-color-black, 20%); // 333333

$link-color-base: $color-primary;
$link-decoration-base: none;
$link-hover-color-base: #000;
$link-hover-decoration-base: none;

$font-family-base: 'Montserrat', Arial, sans-serif;
$font-family-primary: 'Ubuntu', Arial, sans-serif;
$font-size: 20px;
$font-size-h1: 40px;
$font-size-h2: 36px;
$font-size-h3: 30px;
$font-size-h4: 20px;
$font-size-h5: 15px;
$font-size-h6: 10px;

$line-height-base: 1.4; // Always use integer no px or rem values

$heading-margin: 0 0 rem(20px) 0;
$heading-font-family: inherit;
$heading-font-weight: 600;
$heading-line-height: 1.1;
$heading-color: inherit;
